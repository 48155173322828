import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const getURL = () => {
  const urlConfigByHostname = {
    "mymonop.monoprix.fr": "https://mymonop.monoprix.fr",
    rec: "https://mymonop-rec.monoprix.fr",
    pql: "https://mymonop-pql.monoprix.fr",
    default: "https://mymonop-pql.monoprix.fr",
  };
  const hostname: string = window.location.hostname;
  const envKey =
    Object.keys(urlConfigByHostname).find((key) => hostname.includes(key)) ||
    "default";
  return urlConfigByHostname[envKey];
};

if (window.location.pathname.includes("pwa")) {
  window.location.href = getURL();
} else {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
