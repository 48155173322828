import React, { useEffect, useState } from "react";
import "./App.css";
import OnBoarding from "./components/OnBoarding/OnBoarding";
import PWA from "./components/PWA/PWA";

function App() {
  const [displayOnBoardings, setDisplayOnBoardings] = useState<boolean>(true);
  useEffect(() => {
    document.addEventListener("closeOnBoardingScreen", () => {
      setDisplayOnBoardings(false);
    });
  }, []);

  return (
    <div className="App">{displayOnBoardings ? <OnBoarding /> : <PWA />}</div>
  );
}

export default App;
